<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <div class="flex h-screen bg-gray-200 pt-24 pb-16">
      <loading
        :active.sync="isSubmitted"
        color="#ff9300"
      />
      <form
        class="w-full max-w-sm bg-white shadow-sm rounded m-auto"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <div class="px-6 pt-6 pb-6">
          <div class="w-full mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >Code</label>
 
            <ValidationProvider
              v-slot="{ errors }"
              name="code"
              rules="numeric|length:6"
              mode="eager"
            >
              <input
                v-model="confirm.code"
                class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none "
                type="text"
                placeholder="123456"
              >
              <span class="text-red-500 text-xs inline-block">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>

          <div class="w-full mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstName"
            >New password</label>
            <ValidationProvider
              v-slot="{ errors }"
              name="password"
              rules="required"
              mode="eager"
            >
              <input
                v-model="confirm.password"
                class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none"
                type="password"
                :placeholder="passwordPlaceholder"
              >
              <p class="text-red-500 text-xs inline-block">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>

          <div class="w-full">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstName"
            >Confirm new password</label>
            <ValidationProvider
              v-slot="{ errors }"
              name="confirm password"
              rules="required"
              mode="eager"
            >
              <input
                v-model="confirm.confirmPassword"
                class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-none"
                type="password"
                :placeholder="passwordPlaceholder"
              >
              <p class="text-red-500 text-xs inline-block">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>
        </div>

        <button
          class="w-full shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-3 px-4 rounded-b"
          type="submit"
        >
          Change password
        </button>
      </form>
    </div>
  </ValidationObserver>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "ConfirmForgotPassword",
  components: {
    Loading,
  },
  data() {
    return {
      confirm: {
        code: "",
        password: "",
        confirmPassword: "",
      },
      passwordPlaceholder: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022",
      isSubmitted: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isSubmitted = true;
    },
  },
};
</script>
